<template >
    <Layout style="background:white;">
        <PageHeader :title="title" :items="items" />

        <div class="row">
          <div class="col-sm-12 col-md-4 bg-white p-0" style="max-height:calc(100vh - 154px);overflow:auto;">
            <div class="d-flex flex-column"  >
                <div style="cursor:pointer"  v-for="chatItem in chat" :key="chatItem.phone+'_chat'" class="d-flex p-3  pr-1 chat-element " :class="currentChat==chatItem?'opened':''" @click="currentChat=chatItem;scrollChat();loadUser();">
                    <div style="max-height: calc(100vh - 154px);border-radius:5000px;min-height:50px;min-width:50px;height:50px;width:50px;background-color:#1e3f89"
                     class="d-flex justify-content-center align-items-center text-white">
                        <h2 class="m-0 text-white"><strong>{{chatItem.name[0]}}</strong></h2>
                    </div>
                    <div class="d-flex flex-column mt-1 ml-2">
                        <b style="color:#1e3f89">{{chatItem.name}}</b>
                        <small style="max-width:280px; overflow: hidden;white-space: nowrap;  text-overflow: ellipsis;">{{chatItem.last_message}}</small>
                        <small style="color:#1e3f89;max-width:300px; overflow: hidden;white-space: nowrap;  text-overflow: ellipsis;font-size:7pt;">{{chatItem.date}}</small>
                    </div>
                </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-8 d-flex flex-column flex-nowrap" style="background:#f1f5f7;height:calc(100vh - 154px);padding-right:0">
              <div class="d-flex flex-column flex-grow-1" v-if="currentChat" style="overflow:auto;" id="message-chat-content">
                  <div v-for="(message,index) in rawChat[currentChat['userID']]" :key="'message_'+index" :class="message.senderUID==''?'from-me':'to-me'">
                      <p>{{message.content}}</p>
                      <span>{{message.created_at?(message.created_at.split('T').join(' ').split('.')[0]):""}}</span>
                  </div>
                   
                  <!-- <div class="to-me">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                      <span>2022-03-19 20:30</span>
                  </div> -->
              </div>
              <div class="pr-4"  v-if="currentChat">
                <div style="height:40px;background:white;border-radius:50000px" class="w-100 m-2 shadow-lg d-flex align-items-center px-3 mr-5">
                    <input v-on:keyup.enter="sendMessage"  type="text" class=" typehere flex-grow-1 border-0" placeholder="Type something here..." :disabled="sendingMessage" v-model="content" />
                    <div @click="sendMessage" style="background:#1e3f89;height:30px;width:30px;border-radius:50%;cursor:pointer" class="d-flex justify-content-center align-items-center ml-3">
                        <i class=" fa fa-chevron-right text-white"></i>
                    </div>
                </div>
              </div>
          </div>
        </div>
    </Layout>

</template>

<script>
  import Layout from "../../layouts/main";
  import {getUser} from '../../../helpers/helper';
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import {makeToast} from '@/helpers/helper.js';
    // import { collection, query,/*doc,*/ onSnapshot } from "firebase/firestore";
    // import {  where, onSnapshot } from "firebase/firestore";
    import firebase from "../../../firebaseInit";

    const db = firebase.firestore();
    export default {
        page: {
            title: "Chat",
            meta: [{ name: "Chat description", content: appConfig.description }]
        },
        components: { Layout, PageHeader },
          data() {
            return {
                currentUser:getUser(),
                categoryData: [],
                categoryName: null,
                offerData: [],
                rationed: "",
                price:"",
                name:"",
                frequency:"",
                data_only: "",
                data_amount:"",
                descrip:"",
                ration_frequency:"",
                onnet_amount:"",
                offnet_amount:"",
                validity:"",
                hot_offer:"",
                selectedOffer:"",
                option:[
                  "True",
                    "False"
                ],

                cat:"",
                tableData: "",
                title: "View Mobile Offer",
                items: [
                    {
                        text: "Dashboard",
                        href: "/"
                    },
                    {
                        text: "Mobile Offer",
                        active: true
                    }
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "",
                sortDesc: false,
                fields: [
                    { key: "id", sortable: true },
                    { key: "name", sortable: true },
                    { key: "frequency", sortable: false },
                    { key: "data_amount", sortable: false },
                    { key: "price", sortable: false },
                    { key: "actions", sortable: false }
                ],
                value:null,
                chat:[],
                currentChat:null,
                rawChat:{},
                sendingMessage:false,
                currentUserInfo:null


            };
        },
        methods:{
            sendNotif(){
                // var $sk =" AAAAM7nkXw8:APA91bFcCdI_9GG7EpZUbu7Hz4mpuWWl90EltwyOfFPph3FHNimMRfsunwqkXJ8p87Uex8arNCkd4wihevDbqhIV8_KaiIZZt9Qft3m8Gqt7X_ZOGXZA3Zj-Z78OGGBg5n5nDL_iUJHv";
                // var link ="https://fcm.googleapis.com/fcm/send";
            },
            async loadUser(){
                try{
                    this.currentUser=null;
                    const docsSnap  = await db.collection("Users").where('phone','==',this.currentUser.userID ).get();
                        let users = [];
                            docsSnap.forEach((doc) => {
                            users.push(doc.data());
                            // console.log(doc.data());
                    });
                    this.currentUser =users.length>0?users[0]:null;
                }catch(e){
                    console.log(e);
                }
                //
            },
    toIsoString(date) {
        var tzo = -date.getTimezoneOffset(),
            dif = tzo >= 0 ? '+' : '-',
            pad = function(num) {
                return (num < 10 ? '0' : '') + num;
            };

        return date.getFullYear() +
            '-' + pad(date.getMonth() + 1) +
            '-' + pad(date.getDate()) +
            'T' + pad(date.getHours()) +
            ':' + pad(date.getMinutes()) +
            ':' + pad(date.getSeconds()) +
            dif + pad(Math.floor(Math.abs(tzo) / 60)) +
            ':' + pad(Math.abs(tzo) % 60);
        },
            sendMessage(){
                if(this.sendingMessage){
                    return;
                }
                if(!this.content||!this.currentChat){
                    return;
                }
                this.sendingMessage=true;
                const dt = this.toIsoString(new Date()).split("+")[0];
                db.collection("Messages")
                    .add({
                        content: this.content,
                        created_at:dt,
                        updated_at:dt,
                        senderUID:"",
                        receiverUID:this.currentChat['userID'],
                        type:"TEXTE",
                        "nom":this.currentUser?this.currentUser['surname']:'',
                        "role":this.currentUser?this.currentUser['role']:''
                    })
                    .then(() => {
                        this.sendingMessage=false;
                        this.content="";
                    })
                    .catch((error) => {
                        makeToast("Connection error. Please refresh")
                        this.sendingMessage=false;
                        console.error("Error: ", error);
                    });
            },
            processChatData(messages){
                var groupBy = function(xs, key) {
                    return xs.reduce(function(rv, x) {
                        (rv[x[key]] = rv[x[key]] || []).push(x);
                        return rv;
                    }, {});
                };

                var rawChat = groupBy(messages,"userID");


                var scrollToBottom = false;
                if(this.currentChat){
                    if(rawChat[this.currentChat['userID']].length!=this.rawChat[this.currentChat['userID']].length){
                        scrollToBottom=true;
                    }
                }
                this.rawChat =rawChat;
                console.log(rawChat)
                console.log(Object.keys(rawChat))
                var chat = []
                Object.keys(rawChat).forEach(userID=>{
                    let name =  userID;
                    for( var i=0; i<rawChat[userID].length;i++){
                        if(rawChat[userID][i].nom && rawChat[userID][i].senderUID!=""){
                            name =rawChat[userID][i].nom;
                            break;
                        }
                    }
                    const ourMessages = rawChat[userID]?rawChat[userID]:rawChat[parseInt(userID)]
                    const countOurMessages = ourMessages.length;
                    chat.push({
                        userID,
                        phone:userID,
                        name: name,
                        last_message:ourMessages[countOurMessages-1].content,
                        date:ourMessages[countOurMessages-1].created_at?(ourMessages[countOurMessages-1].created_at.split('T').join(' ').split('.')[0]):"",
                    })
                });
                this.chat = chat;
                if(scrollToBottom){
                      this.scrollChat();
                }
              
            },
            scrollChat(){
                setTimeout(function(){
                    var element = document.getElementById("message-chat-content");
                    element.scrollTop = element.scrollHeight
                    //   element.scrollIntoView();
                },50)
            }
        },
        mounted(){
            // const q = query(collection(db, "Messages")/*, where("state", "==", "CA")*/);
            // const messages = [];

        db.collection("Messages")
        .onSnapshot((querySnapshot) => {
                var messages = [];
                querySnapshot.forEach((doc) => {
                    var content =doc.data();
                    if(content.receiverUID){
                        content.userID = content.receiverUID
                    }else{
                        content.userID = content.senderUID
                    }
                    content.date = new Date(content.created_at)
                    messages.push(content);
                });
                messages.sort((a,b)=>a.date.getTime()>b.date.getTime()?1:-1)
                this.processChatData(messages)
                // console.log("Current cities in CA: ", messages.join(", "));
            });


            // onSnapshot(q, (querySnapshot) => {
            //     querySnapshot.forEach((doc) => {
            //         messages.push(doc.data().name);
            //     });
            //     console.log("Current messages: ", messages.join(", "));
            // });
            // db.collection('cities')..onSnapshot(function(querySnapshot) {
            // var cities = [];
            // querySnapshot.forEach(function(doc) {
            //     cities.push(doc.data().name);
            // });
            // console.log("Current cities in CA: ", cities.join(", "));
            // });
        }
    }
</script>

<style scoped>
  .from-me{
     background-color:#1e3f89;
     color:white;
     padding:10px 20px;
     max-width: 50%;
     border-radius: 20px 20px 0px 20px;
     margin: 10px 0;
     /* float: left; */
     align-self: flex-end;
  }
  .from-me >p{
      margin-bottom:0;
      font-size: 10.5pt;
  }
  .from-me > span{
      opacity: 0.3;
      font-size: 8pt;
      /* float: right; */
  }

  .to-me{
     background-color:white;
     color:#1e3f89;
     padding:10px 20px;
     max-width: 50%;
     border-radius: 20px 20px 0px 20px;
     margin: 10px 0;
     /* float: left; */
     align-self: flex-start;
  }
  .to-me >p{
      margin-bottom:0;
      font-size: 10.5pt;
  }
  .to-me > span{
      opacity: 0.3;
      font-size: 8pt;
      float: right;
  }
  .chat-element.opened{
      background:#f1f5f7;
  }

.typehere:focus{
border:0;
outline: none;
}
</style>